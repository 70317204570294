$(function(){
  "use strict";
  /*=======================================
  sticky-header.js
  ======================================= */

  $(window).width() >= "768" && $(".navigation").sticky({ topSpacing: 0 })

  /*remove preload slider */
  $(".slides__preload_wrapper").fadeOut(1500);

  /* =======================================
  single Page Nav
  =======================================*/
  // The actual plugin
  $('.single-page-nav').singlePageNav({
    offset: $('.single-page-nav').outerHeight(),
    filter: ':not(.external)',
    updateHash: true,
  });

  /*=======================================
  Scroll Top
  =======================================*/
  $(".scrollup").on('click', function () {
    $('html,body').animate({
      'scrollTop': '0'
    }, 600);
    return false;
  });

  /* =======================================
    For Menu
  =======================================*/
  $("#navigation").menumaker({
    title: "",
    format: "multitoggle"
  });

  $('.file-upload').on("change", function () {
    var i = $(this).prev('label').clone();
    var file = $(this)[0].files[0].name;
    $(this).prev('i').text(file);
  });

  /*==============================
      Mobile Menu
  ================================*/
  jQuery('.main-menu nav').meanmenu({
    meanScreenWidth: '991',
    meanMenuContainer: '.mobile-menu',
    meanRevealPosition: 'right',
    meanMenuOpen: "<span></span><span></span><span></span>"
  });

  $(window).resize(function() {
    if ($(window).width() <= 991) {
      $('.mobile-menu ul').removeClass('nav menu navbar-nav');
    } else {
      $('.mobile-menu ul').addClass('nav menu navbar-nav');
    }
  }).resize(); // trigger resize event initially
});

jQuery(document).ready(function () {

});