$(function() {
  $('.slider').slick({
    autoplay: true,
    dots: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    /*prevArrow: '<img src="https://hp-shueki.jp/hp/images/bx_arrow_prev.png" class="slide-arrow prev-arrow">',
    nextArrow: '<img src="https://hp-shueki.jp/hp/images/bx_arrow_next.png" class="slide-arrow next-arrow">',*/
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 6
      }
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2
      }
    }]
  });
});