$(function(){
      $("#slider-two").owlCarousel({
          loop:true,
          nav:true,
          autoplaySpeed: 3000,
          autoplay:true,
          dots:false,
          navText: ["<i class='fa fa-arrow-left'></i>","<i class='fa fa-arrow-right'></i>"],
          responsive:{
              0:{
                  items:1,
                  nav:false,
                  autoplay:true,
                  autoplayHoverPause:true
              },
              600:{
                  items:1
              },
              1000:{
                  items:1
              }
          }

      });
    });
